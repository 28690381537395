import { useQuery } from '@tanstack/react-query';
import { omitBy } from 'lodash';

import { get } from 'util/httpsClient';

export function useGetProducts({
  filters,
  page = 1,
}: {
  filters: {
    title: string;
    departmentId?: string;
    categoryId?: string;
    productTypeId?: string;
    productSubtypeId?: string;
    colorId?: string;
    brandId?: string;
  };
  page?: number;
}) {
  const pageSize = 10;
  const pagination = {
    limit: String(pageSize),
    offset: String((page - 1) * pageSize),
  };
  const filteredFilters = omitBy(filters, v => !v);
  const queryParams = { ...filteredFilters, ...pagination };

  return useQuery({
    queryKey: ['products', queryParams],
    queryFn: () => {
      return get({
        path: `/products?${new URLSearchParams(queryParams)}`,
      });
    },
    keepPreviousData: true,
    meta: {
      errorMessage: 'Failed to fetch products',
    },
  });
}
