import * as Portal from '@radix-ui/react-portal';
import { atom, useAtom } from 'jotai';
import React, { MouseEventHandler } from 'react';

import css from './ButtonsNavigation.module.css';
import { IconChevronLeft, IconChevronRight } from 'assets/icons';

const renderElementAtom = atom<HTMLDivElement | null>(null);

export const ButtonsNavigationRenderContainer = () => {
  const [, setRenderElement] = useAtom(renderElementAtom);

  return <div className={css.renderContainer} ref={el => setRenderElement(el)} />;
};

type P = {
  onLeftClick?: MouseEventHandler<HTMLButtonElement>;
  onRightClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  leftLabel?: string;
  rightLabel?: string;
};

const ButtonsNavigation: React.FC<P> = ({
  onLeftClick,
  onRightClick,
  disabled,
  leftLabel,
  rightLabel,
}) => {
  const [renderElement] = useAtom(renderElementAtom);

  return (
    <Portal.Root container={renderElement} asChild>
      <div className={css.root}>
        <div className={css.maxWidthWrapper}>
          <button onClick={onLeftClick}>
            <IconChevronLeft />
            {leftLabel || 'Back'}
          </button>
          <button onClick={onRightClick} type="submit" disabled={disabled}>
            {rightLabel || 'Next'}
            <IconChevronRight />
          </button>
        </div>
      </div>
    </Portal.Root>
  );
};

export default ButtonsNavigation;
