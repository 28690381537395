import { useQuery } from '@tanstack/react-query';

import { FILTERS } from 'containers/ResellLandingPage/ProductSearch';
import { get } from 'util/httpsClient';

export function useGetProductFilterOptions() {
  return useQuery({
    queryKey: ['options'],
    queryFn: () => {
      return get({ path: '/configuration' })
        .then(res => res.enums || {})
        .then(res =>
          [...Object.keys(FILTERS)].map(k => ({
            ...FILTERS[k],
            options: res?.[k]?.map(option => ({ ...option, label: kebapToHuman(option.name) })),
          }))
        );
    },
    meta: {
      errorMessage: 'Failed to fetch product filter options',
    },
  });
}
function kebapToHuman(str: string) {
  return str
    .split('-')
    .map(s => s[0].toUpperCase() + s.slice(1))
    .join(' ');
}
