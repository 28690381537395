import { useMutation } from '@tanstack/react-query';

import { useGetBrandIdOptions } from './useGetBrandIdOptions';
import { useGetProductFilterOptions } from './useGetProductFilterOptions';
import {
  useCreateListingDraft,
  useRequestImageUpload,
} from 'containers/EditListingPage/EditListingPage.duck';

export const useCreateDraftFromProduct = () => {
  const { data: filterOptions } = useGetProductFilterOptions();
  const createDraftListing = useCreateListingDraft();
  const onImageUpload = useRequestImageUpload();
  const { data: brandData } = useGetBrandIdOptions();

  return useMutation({
    mutationFn: async (product: any) => {
      const uploadedPhotos = await Promise.all(
        product.photos?.map(async photo => {
          // Download the photo url
          const res = await fetch(photo);

          // Convert it to a blob
          const blob = await res.blob();

          // Create a file from the blob
          const file = new File([blob], 'photo.jpg', { type: blob.type });

          // Upload the file
          return onImageUpload({
            id: `${file.name}_${Date.now()}`,
            file,
          });
        })
      );
      const uploadedPhotoIds = uploadedPhotos.map(photo => photo.id?.uuid);

      // HACK: This is so we don't set partnerId for Shrimps listings, but are still able to  use their DB for listing
      const partnerId =
        product.brandName === 'Shrimps'
          ? undefined
          : String(brandData?.brands?.find(b => b.id === product.brandId)?.partnerId);

      const listingDraft = {
        title: product.title || '',
        description: product.description || '',
        images: uploadedPhotos,
        publicData: {
          fromNoldDatabase: true,
          originalPhotoIds: uploadedPhotoIds,
          priceWhenBought: Number(product.originalPrice || 0)
            ? {
                amount: Number(product.originalPrice || 0) * 100,
                currency: 'GBP',
              }
            : undefined,
          brandId: String(product.brandId),
          brandName: product.brandName,
          partnerId,
          department: filterOptions
            ?.find(f => f.filterName === 'departmentId')
            ?.options?.find(o => o.id === product.departmentId)?.name,
          category: filterOptions
            ?.find(f => f.filterName === 'categoryId')
            ?.options?.find(o => o.id === product.categoryId)?.name,
          productType: filterOptions
            ?.find(f => f.filterName === 'productTypeId')
            ?.options?.find(o => o.id === product.productTypeId)?.name,
          productSubtype: filterOptions
            ?.find(f => f.filterName === 'productSubtypeId')
            ?.options?.find(o => o.id === product.productSubtypeId)?.name,
          colors: [
            filterOptions
              ?.find(f => f.filterName === 'colorId')
              ?.options?.find(o => o.id === product.colorId)?.name,
          ],
        },
      };

      return (createDraftListing(listingDraft) as any).then(res => res?.data?.data);
    },
    meta: { name: 'createDraftFromProduct' },
  });
};
