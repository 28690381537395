import * as Popover from '@radix-ui/react-popover';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { LayoutSingleColumn, Page } from '../../components';
import { useIntl } from '../../util/reactIntl';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import ProductSearch from './ProductSearch';
import css from './ResellLandingPage.module.css';
import { IconSearch } from 'assets/icons';
import { useRouteConfiguration } from 'context/routeConfigurationContext';
import { useGetBrandIdOptions } from 'hooks/api/listings/useGetBrandIdOptions';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { useQueryParams } from 'hooks/useQueryParams';
import { pathByRouteName } from 'util/routes';

export const ResellLandingPage = () => {
  const scrollingDisabled = useIsScrollingDisabled();
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'ListingPage.title' });

  const [{ brandId }] = useQueryParams({
    brandId: '',
  });

  const subpage = brandId ? <ProductSearch /> : <BrandSearch />;

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn topbar={<TopbarContainer currentPage="ContactPage" />}>
        {subpage}
      </LayoutSingleColumn>
    </Page>
  );
};

const BrandSearch = () => {
  const [, setParams] = useQueryParams({
    brandId: '',
  });

  const [search, setSearch] = React.useState('');
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const { data } = useGetBrandIdOptions();
  const brands = data?.brands || [];
  const filteredBrands = brands.filter(brand =>
    brand.name.toLowerCase().includes(search.toLowerCase())
  );
  const noFilteredResults = filteredBrands.length === 0 && search.length > 0;

  const history = useHistory();
  const routeConfiguration = useRouteConfiguration();
  const navigateToBrand = (brand: any) => {
    if (brand.partnerId) {
      setParams({ brandId: String(brand.id) });
    } else {
      history.push(
        pathByRouteName('NewListingPage', routeConfiguration) +
          '?' +
          new URLSearchParams({ brandId: String(brand.id) }).toString()
      );
    }
  };

  return (
    <div className={css.brandSearchRoot}>
      <h1 className={css.mainText}>Which brand are you selling</h1>
      <label className={css.search}>
        <Popover.Root open={search.length > 0}>
          <Popover.Anchor className={css.search__inputWrapper}>
            <IconSearch />
            <input
              type="text"
              name="brandId"
              value={search}
              ref={inputRef}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={e => {
                if (
                  e.key === 'ArrowDown' ||
                  e.key === 'Enter' ||
                  (!e.shiftKey && e.key === 'Tab')
                ) {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                  }

                  // First button in the popover
                  const button = popoverRef.current?.querySelector('button');
                  button?.focus();
                }
              }}
            />
          </Popover.Anchor>
          <Popover.Portal>
            <Popover.Content
              ref={popoverRef}
              onOpenAutoFocus={e => e.preventDefault()}
              className={css.search__popoverContent}
              avoidCollisions={false}
              collisionPadding={32}
              sideOffset={17}
            >
              <div className={css.search__popoverContentWidthWrapper}>
                {noFilteredResults && (
                  <div>
                    {search} - Not yet present in our curated brand list. Please search for another
                    brand.
                  </div>
                )}
                {filteredBrands?.map(brand => (
                  <button
                    key={brand.id}
                    onClick={() => {
                      navigateToBrand(brand);
                    }}
                    onKeyDown={e => {
                      if (e.key === 'ArrowUp' || (e.shiftKey && e.key === 'Tab')) {
                        e.preventDefault();
                        e.stopPropagation();
                        const prevElement = e.currentTarget.previousElementSibling as HTMLElement;

                        if (!prevElement) {
                          inputRef.current?.focus();
                        }

                        prevElement?.focus?.();
                      }
                      if (e.key === 'ArrowDown') {
                        e.preventDefault();
                        e.stopPropagation();
                        const nextElement = e.currentTarget.nextElementSibling as HTMLElement;
                        nextElement?.focus?.();
                      }
                      if (e.key === 'Enter') {
                        navigateToBrand(brand);
                      }
                    }}
                  >
                    {brand.name}
                  </button>
                ))}
              </div>
            </Popover.Content>
          </Popover.Portal>
        </Popover.Root>
      </label>
    </div>
  );
};

export default ResellLandingPage;
